<template>
  <NuxtLink class="preview-newest" :to="'/' + article.full_slug">
    <BaseNuxtImage
      :src="imgSrc"
      :alt="imgTitle"
      loading="lazy"
      class="preview-newest__image"
    />
    <div class="preview-newest__info">
      <div class="preview-newest__pill">Most recent</div>
      <h2 class="preview-newest__title heading--5">
        {{ article.content.meta.title }}
      </h2>
      <p class="preview-newest__description">
        {{ article.content.meta.description }}
      </p>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { Story } from '@/types'

interface Props {
  article: Story<any>
  hideAuthor?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hideAuthor: false,
})

const imgSrc = computed(() => {
  return props.article.content?.meta.og_image
})
const imgTitle = computed(() => {
  return props.article.content?.meta.og_title !== '' ||
    props.article.content?.meta.og_title !== undefined
    ? props.article.content.meta.og_title
    : 'preview image'
})
</script>

<style lang="scss" scoped>
.preview-newest {
  $root-el: #{&};

  background-color: white;
  padding: 36px;
  border-radius: 16px;
  color: var(--cobalt-800);
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 40px;
  margin-bottom: 56px;

  @media (min-width: $breakpoint-lg) {
    padding: 40px;
    grid-template-columns: 1.15fr 1fr;
    margin-bottom: 96px;
  }

  &:hover {
    text-decoration: none;

    #{$root-el}__title {
      color: var(--color-link);
    }
  }

  &__info {
    position: relative;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 47/26;
    border-radius: 16px;
    margin-bottom: 40px;

    @media (min-width: $breakpoint-lg) {
      margin-bottom: 0;
    }
  }

  &__author {
    margin-bottom: 14px;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__description {
    font-size: var(--font-size-paragraph-s);
    margin-bottom: 0;
  }

  &__pill {
    @include paragraph-2xs;

    border-radius: 9px;
    line-height: 1em;
    padding: 4px 12px;
    border: 1px solid var(--navy-600);
    width: fit-content;
    margin-bottom: 24px;
    font-weight: 600;
    letter-spacing: 0.75;
    text-transform: uppercase;
  }
}
</style>
