<template>
  <nav class="blog-nav">
    <div class="blog-nav__left">
      <NuxtLink class="blog-nav__link" to="/blog/articles">
        All articles
      </NuxtLink>
      <NuxtLink class="blog-nav__link" to="/blog/categories/company">
        Company
      </NuxtLink>
      <NuxtLink class="blog-nav__link" to="/blog/categories/technology">
        Technology
      </NuxtLink>
      <NuxtLink class="blog-nav__link" to="/blog/categories/digital-health">
        Digital Health
      </NuxtLink>
    </div>
    <div class="blog-nav__right">
      <NuxtLink
        class="blog-nav__link blog-nav__link--with-icon"
        to="/newsletter"
      >
        Join newsletter
      </NuxtLink>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.blog-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 24px;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 64px;
    flex-direction: row;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 12px;
    row-gap: 8px;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-md) {
      column-gap: 16px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__link {
    @include shadow-flat-soft;

    display: flex;
    align-items: center;
    color: var(--color-text);
    font-weight: var(--font-weight-paragraph-bold);
    background-color: white;
    padding: 12px 24px;
    border-radius: 16px;

    &:hover {
      text-decoration: none;
      color: var(--color-link);
    }
  }
}
</style>
